<template>
    <v-card
      elevation="2"
      class="pb-2"
    >
      <v-toolbar
          color="#4ECDC4"
          dark
          flat
      ><span class="text-h6">お問合せ内容</span>
        <v-spacer></v-spacer>
      <v-btn 
        icon
        @click="close"
      > 
        <v-icon>{{icons.close}}</v-icon>
      </v-btn>
      </v-toolbar>
    
    <v-simple-table
      class="text--secondary ml-3 "
    >

      <template v-slot:default>
        <tbody>
          <tr
            v-for="item in deserts"
            :key="item.name"
          >
              <td class="text--secondary" >{{ item.caption }}</td>
              <td v-if="item.caption!='状態' && item.caption!='お問合せ内容' ">{{ item.data }}</td>
              <td v-else > 
                <div v-if="item.data" >   
                  <v-chip
                    class="ma-2"
                    color="green"
                    text-color="white"
                  >
                    解決
                  </v-chip>
                </div>
                <div v-else  class="d-flex no-wrap">
                  <v-chip
                    class="ma-2"
                    color="red"
                    text-color="white"
                  >
                    未解決
                  </v-chip> 
                  
                  <v-btn 
                    text
                    class="my-auto mr-0 ml-auto"
                    color="blue" 
                    @click="changeStatusDialog(false)" 
                    >解決にする</v-btn>
                </div>
              </td>
              
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card
      width="95%"
      class="pa-2 mx-auto mt-6 text--secondary "
      elevation="0"
      outlined
    >
    <div style="width:100%;  position: absolute; top:-12px; left:-3px; text-align:center;">
      <label  style="padding:0 8px 0 8px; background:white;  font-size:15px;">内容</label>
    </div>
    {{this.details}}</v-card>

      <v-dialog v-model="dialog" max-width="400px">
        <v-card
          :loading="loading"
          :disabled="loading"
        >
         <v-card-title>
            <span class="text--secondary font-weight-bold">状態の変更</span>
         </v-card-title>
        <v-card-text class="text-center"> 
          ステータスを

          <!-- <v-chip v-if="targetStatus"
            class="ma-2"
            color="red"
            text-color="white"
          >未解決</v-chip>  -->

          <v-chip
            class="ma-2"
            color="green"
            text-color="white"
          >解決</v-chip>

          に変更しますか？
        </v-card-text>
        <div width="100%" class="text-center pb-2">
          <v-btn
            color="primary"
            outlined
            text
            class="ma-1"
            @click="dialog = false"
          >閉じる</v-btn>
          <v-btn
            color="green"
            outlined
            class="ma-1"
            text 
            @click="changeStatus()"
          >変更</v-btn>
        </div>
        </v-card>
      </v-dialog>
    </v-card>

  

</template>

<script>
import axios from "axios"
import { mdiClose } from '@mdi/js';
  export default {
    name: 'DetailInquiry',
    props:["detailItem"],
    data: () => ({
      icons:{"close":mdiClose},
       deserts:[
        { caption:"お問合せID" , data:""},
        { caption:"名前",date:""},
        { caption:"メールアドレス" , data:""},
        { caption:"種類" , data:""},
        { caption:"投稿日" , data:""},
        { caption:"状態" , data:""},
      ],
      itemList:["inquiryId","name","email","type","created","close"],
      dItem:null,
      details:null,
      dialog:false,
      targetStatus:false,
      loading:false,
      rules: [
        v => !!v || "必須項目です。",
      ],
      inquiryType:null,
      password1:null,
      password2:null,
      passwordOriginal:null,
    }),

    created(){
      this.dItem = this.detailItem;
    },

    watch: {
       dItem:{
            handler(){
                for(let i = 0 ; i < this.itemList.length; i++){
                  this.deserts[i].data = this.dItem[this.itemList[i]];
                }
                this.details = this.dItem["details"];
            },
            deep: true,
        },
        detailItem :{
            handler(){
              this.dItem = this.detailItem;
            },
            deep: true,
        }

    },

    methods: {

    close (){
      this.$emit('closeEmit')
    },

    //お問い合わせのステータス変更のダイアログの表示
    changeStatusDialog(status){
      this.targetStatus = status;
      this.dialog = true;
    },

    //ステータスを変更
    changeStatus(){
      this.loading = true;
      axios({
        method: "PUT",
        url:
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/inquiries/${this.dItem["inquiryId"]}`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId, "Content-Type":"application/json" },
        data:{
          close:true,
        }
        ,
      },
      ).then(() => {
        // console.log(response)
        this.loading = false;
        this.$emit('changeStatusEmit')

      }).catch(reason => {
        console.log(reason)
      });

    }
 
  },

  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

</style>

